﻿@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette:  map.get($color-config, 'accent');
  $warn-palette:    map.get($color-config, 'warn');
  $is-dark-theme:   map.get($color-config, 'is-dark');

  .accordion-panel {
    color: mat.get-color-from-palette($primary-palette);
  }

  .nav-item {
    cursor: pointer;
    box-sizing: border-box;
    border: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 0 30px 30px 0;
    padding: 16px 16px;
  }

  .nav-item:hover {
    background: rgba(128,128,128,0.1);
  }

  .group-text {
    display: inline-block;
    padding-left: 12px;
    font-size: 1.1em;
    font-family: SparkeBody, sans-serif;
  }

  .groupactive {
    background-color: mat.get-color-from-palette($primary-palette, 50) !important;
  }

}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
