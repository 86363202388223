@use '@angular/material' as mat;

@use '../app/components/standard-layout/standard-layout-theme.component' as standardLayout;
@use '../app/components/organisation/organisation-theme.component' as organisation;
@use "../app/shared/components/sitenav-group/sitenav-group.component-theme" as sitenavGroup;

@use '@ng-matero/extensions' as mtx;

@import '@angular/material/theming';

@include mat.core();

@import './palettes';

$palette-red:           mat.define-palette(mat.$red-palette);
$palette-glowingcoral:  mat.define-palette($md-glowingcoral);
$palette-glowingcoral2:  mat.define-palette($md-glowingcoral2);
$palette-electricspark:  mat.define-palette($md-electricspark);
$palette-electricspark2:  mat.define-palette($md-electricspark2);
$palette-zingylavender:  mat.define-palette($md-zingylavender);
$palette-zingylavender2:  mat.define-palette($md-zingylavender2);
$palette-concrete:  mat.define-palette($md-concrete);

$sparke-typography: mat.define-typography-config(
  $font-family: 'SparkeBody'
);

$theme-sparke: mat.define-light-theme((
  color: (
    primary: $palette-glowingcoral,
    accent: $palette-zingylavender2,
    warn: $palette-red,
  ),
  typography: $sparke-typography
));

@include mat.all-component-themes($theme-sparke);
@include standardLayout.color($theme-sparke);
@include organisation.color($theme-sparke);
@include sitenavGroup.color($theme-sparke);
@include mtx.all-component-themes($theme-sparke);

.mat-typography h1, .mat-typography h2, .mat-typography h3, .mat-typography h4, .mat-typography h5, .mat-typography h6 {
  font-family: SparkeHead;
}
