@use 'sass:color';
@use 'sass:map';

@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");

  $base-colour: mat.get-color-from-palette($primary-palette, 500);
  $side-colour: mat.get-color-from-palette($primary-palette, 50);

  // $side-colour: color.scale($base-colour, $saturation: -30%, $lightness: 80%);
  $disabled-colour: color.scale(
    $base-colour,
    $saturation: -60%,
    $lightness: 20%
  );

  .standard-layout-sidenav {
    width: 242px;
    background-color: rgb(245,245,245);

    mat-nav-list .active, mat-nav-list .active:hover, mat-nav-list .active:focus {
      /*background-color: $base-colour;
      color: #f8f8f8;*/
    }

    mat-nav-list .mat-list-item-disabled {
      background-color: transparent;
      color: $disabled-colour;
    }
  }
}
