@use 'sass:color';
@use 'sass:map';

@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");

  $base-colour: mat.get-color-from-palette($primary-palette, 500);
  $sub-colour: mat.get-color-from-palette($primary-palette, 100);
  // $sub-colour: #d5c6fb;

  // $side-colour: color.scale($base-colour, $saturation: -30%, $lightness: 80%);
  $title-colour: color.scale(
    $base-colour,
    $saturation: -80%,
    $lightness: 80%
  );

  .subactive {
    background-color: $sub-colour !important;
    border-radius: 0 30px 30px 0;
  }

  .subitem {
    border-radius: 0 30px 30px 0;
  }

}
