@import "../node_modules/@ag-grid-community/core/dist/styles/ag-grid.scss";
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";

/* Erudus icons for allergens etc */
@import "./assets/erudus/sass/icons";

@font-face {
  font-family: "SparkeBody";
  src: url(assets/font/PolySans-Neutral.woff2),
       url(assets/font/PolySans-Neutral.woff);
}

@font-face {
  font-family: "SparkeHead";
  src: url(assets/font/PolySans-Median.woff2),
       url(assets/font/PolySans-Median.woff);
}

.ag-theme-material {
  @include ag-theme-material((
    font-family: "SparkeBody"
  ));
}

.ag-theme-alpine {
  @include ag-theme-alpine((
    font-family: "SparkeBody"
  ));
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.container {
  padding: 24px;
}

.nav-icon {
  margin-right: 12px;
}

.snackbar-error {
  background-color: #cc0000 !important;
  color: #ffffff !important;

  .mat-button-wrapper {
    color: #ffff00 !important;
  }
}

fieldset {
  border: 1px solid #dddddd;
  padding: 12px 24px;
  border-radius: 5px;
}

input {
  border-color: #bbbbbb;
}

fieldset legend {
  color: rgba(0, 0, 0, 0.6);
  margin-left: -16px;
  font-size: 11px;
}

fieldset.tab-section {
  max-width: 1260px;
  margin-bottom: 24px;
}

.form-column {
  display: flex;
  gap: 24px;
}

.form-column > div {
  flex: 1;
}

/* Custom Button Colour - danger */

.mat-button.mat-danger,
.mat-stroked-button.mat-danger {
  color: #dd0000;
}
.mat-button.mat-danger:hover,
.mat-stroked-button.mat-danger:hover {
  background-color: #f0fff3;
}

.mat-raised-button.mat-danger,
.mat-flat-button.mat-danger,
.mat-fab.mat-danger,
.mat-mini-fab.mat-danger {
  color: #f0fff3;
  background-color: #dd0000;
}

.mat-icon-button.mat-danger {
  color: #dd0000;
}

.action-link {
  color: #333333;
  text-decoration: none;
}

.action-link:hover {
  text-decoration: underline;
}

/* Grid Styles */

.cell-editable {
  background-color: #fff8ee;
}

.cell-editable-right {
  background-color: #fff8ee;
  text-align: right;
}

.max960 {
  max-width: 960px;
}


/* Input */

  $custom-main-color: #bbb;
  $custom-label-color: rgba(0, 0, 0, .6);

  // mat-icon-stepper selected color change
  .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: $custom-main-color !important;
  }

  // input outline color
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: $custom-main-color !important;
  }

  // mat-input focused color
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $custom-main-color !important;
  }

  // mat-input error outline color
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: #ff0000 !important;
    opacity: 0.8 !important;
  }

  // mat-input caret color
  .mat-input-element {
    caret-color: $custom-main-color !important;
  }

  // mat-input error caret color
  .mat-form-field-invalid .mat-input-element, .mat-warn .mat-input-element {
    caret-color: $custom-main-color !important;
  }

  // mat-label normal state style
  .mat-form-field-label {
    color: $custom-label-color !important;
  }

  // mat-label focused style
  .mat-form-field.mat-focused .mat-form-field-label {
    color: $custom-main-color !important;
  }

  // mat-label error style
  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #ff0000 !important;
  }
