/* For use in src/lib/core/theming/_palette.scss */
$md-glowingcoral: (
    50 : #ffebea,
    100 : #ffcdca,
    200 : #ffaca7,
    300 : #ff8b84,
    400 : #ff7269,
    500 : #ff594f,
    600 : #ff5148,
    700 : #ff483f,
    800 : #ff3e36,
    900 : #ff2e26,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffd5d4,
    A700 : #ffbcba,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-electricspark: (
    50 : #fcffe7,
    100 : #f8ffc2,
    200 : #f4ff99,
    300 : #efff70,
    400 : #ebff52,
    500 : #e8ff33,
    600 : #e5ff2e,
    700 : #e2ff27,
    800 : #deff20,
    900 : #d8ff14,
    A100 : #ffffff,
    A200 : #fefffb,
    A400 : #f5ffc8,
    A700 : #f0ffae,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-zingylavender: (
    50 : #faf8ff,
    100 : #f2eefe,
    200 : #eae3fd,
    300 : #e2d7fc,
    400 : #dbcffc,
    500 : #d5c6fb,
    600 : #d0c0fa,
    700 : #cab9fa,
    800 : #c4b1f9,
    900 : #baa4f8,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-glowingcoral2: (
    50 : #fae5e4,
    100 : #f3bebc,
    200 : #ec938f,
    300 : #e46862,
    400 : #de4740,
    500 : #d8271e,
    600 : #d4231a,
    700 : #ce1d16,
    800 : #c81712,
    900 : #bf0e0a,
    A100 : #ffeaea,
    A200 : #ffb8b7,
    A400 : #ff8584,
    A700 : #ff6c6a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-electricspark2: (
    50 : #f8fbe0,
    100 : #eef4b3,
    200 : #e2ed80,
    300 : #d6e64d,
    400 : #cee026,
    500 : #c5db00,
    600 : #bfd700,
    700 : #b8d200,
    800 : #b0cd00,
    900 : #a3c400,
    A100 : #fbffec,
    A200 : #f2ffb9,
    A400 : #e8ff86,
    A700 : #e3ff6d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-zingylavender2: (
    50 : #f3f1f8,
    100 : #e2dbed,
    200 : #cec4e1,
    300 : #baacd5,
    400 : #ac9acc,
    500 : #9d88c3,
    600 : #9580bd,
    700 : #8b75b5,
    800 : #816bae,
    900 : #6f58a1,
    A100 : #ffffff,
    A200 : #e2d5ff,
    A400 : #bfa2ff,
    A700 : #ae89ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-concrete: (
    50 : #f3f3f3,
    100 : #e1e0e0,
    200 : #cdcccc,
    300 : #b9b8b7,
    400 : #aaa8a7,
    500 : #9b9998,
    600 : #939190,
    700 : #898685,
    800 : #7f7c7b,
    900 : #6d6b6a,
    A100 : #fdefeb,
    A200 : #f8cbbc,
    A400 : #ffa283,
    A700 : #ff8f69,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

